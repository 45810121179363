// Used with AOS (Animate On Scroll Library)
// https://michalsnik.github.io/aos/
// https://github.com/michalsnik/aos
jQuery(document).ready(function() {
    AOS.init();
});



$('a[target="_blank"]').addClass("external-link"),
$(".external-link").append('<span class="sr-only">(opens new window)</span>');

//*****************************************************************************
// Add class .is-active to Foundation's accordion and tabs modules
//*****************************************************************************

/*
$('.tabs > li:first-of-type').addClass('is-active');
$('.tabs > li:first-of-type a').attr('aria-selected','true');
$('.tabs-content div:first-of-type').addClass('is-active');
$('.tabs-content div:first-of-type').attr('aria-hidden','false');
*/


$('.accordion > li:first-of-type').addClass('is-active');
$('.accordion > li:first-of-type a').attr('aria-selected','true');
$('.accordion-content div:first-of-type').addClass('is-active');
$('.accordion-content div:first-of-type').attr('aria-hidden','false');



//*****************************************************************************
// Used to animate the AOS.js
//*****************************************************************************




/*
(function($) {
    $(document).ready(function() {
        $('#cssmenu li.has-sub>a').on('click', function() {
            //$(this).removeAttr('href');
            var element = $(this).parent('li');
            if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('li').removeClass('open');
                element.find('ul').slideUp();
            } else {
                element.addClass('open');
                element.children('ul').slideDown();
                element.siblings('li').children('ul').slideUp();
                element.siblings('li').removeClass('open');
                element.siblings('li').find('li').removeClass('open');
                element.siblings('li').find('ul').slideUp();
            }
        });
        $('#cssmenu>ul>li.has-sub>a').append('<span class="holder"></span>');
        (function getColor() {
            var r, g, b;
            var textColor = $('#cssmenu').css('color');
            textColor = textColor.slice(4);
            r = textColor.slice(0, textColor.indexOf(','));
            textColor = textColor.slice(textColor.indexOf(' ') + 1);
            g = textColor.slice(0, textColor.indexOf(','));
            textColor = textColor.slice(textColor.indexOf(' ') + 1);
            b = textColor.slice(0, textColor.indexOf(')'));
            var l = rgbToHsl(r, g, b);
            if (l > 0.7) {
                $('#cssmenu>ul>li>a').css('text-shadow', '0 1px 1px rgba(0, 0, 0, .35)');
                $('#cssmenu>ul>li>a>span').css('border-color', 'rgba(0, 0, 0, .35)');
            } else {
                $('#cssmenu>ul>li>a').css('text-shadow', '0 1px 0 rgba(255, 255, 255, .35)');
                $('#cssmenu>ul>li>a>span').css('border-color', 'rgba(255, 255, 255, .35)');
            }
        })();

        function rgbToHsl(r, g, b) {
            r /= 255, g /= 255, b /= 255;
            var max = Math.max(r, g, b),
                min = Math.min(r, g, b);
            var h, s, l = (max + min) / 2;
            if (max == min) {
                h = s = 0;
            } else {
                var d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                }
                h /= 6;
            }
            return l;
        }
    });
})(jQuery);
*/


//*****************************************************************************
// Used to animate the WOW.js script and animate css lib
//*****************************************************************************

/*
jQuery(document).ready(function($) {
	if (navigator.userAgent.match(/msie/i) ){ // IE lt IE 10
		//alert('I am an old fashioned Internet Explorer');
	} else if (navigator.userAgent.match(/msie|trident/i)) { // gt IE 10+
		new WOW().init();

	} else {
		new WOW().init();
	}
});
*/





//*****************************************************************************
// Custom hamburger menu for off-canvas menu
//*****************************************************************************

var $hamburger = $(".hamburger, .js-off-canvas-overlay");
$hamburger.on("click", function(e) {
	$hamburger.toggleClass("is-active");
	// Do something else, like open/close menu
});



//*****************************************************************************
// LITY Lightbox
//*****************************************************************************

$('.lity-iframe-container iframe').load(function(){
    $iframe = $('.lity-iframe-container iframe').contents();
    $iframe.find('header').css('display','none');
});



//*****************************************************************************
// Fluidbox
//*****************************************************************************

// *** CAUSES ISSUES WHEN USING LIGHTGALLERY DO TO THE URL / EXT TRIGGER ***
jQuery(document).ready(function(){
    //jQuery('a[rel^="lightbox"]').fluidbox();
	$('a[href$=".jpeg"], a[href$=".jpg"], a[href$=".png"], a[href$=".jpeg"]').fluidbox();
});



//*****************************************************************************
// Remove p tags from images and/or iframes
//*****************************************************************************

jQuery(document).ready(function($) {
    // Unwrap images
    //$('p img').unwrap();
    $('p > img').unwrap();

     // Unwrap images and iframes
    //$('p img, p .fluid-width-video-wrapper').unwrap();

});



//*****************************************************************************
// ACCORDION (Foundation 6)
/* Applies a class to any elements within the selector (i.e. 'toogle-nav a') */
//*****************************************************************************

$(document).ready(function(){
//$('ul.accordion li:first-of-type').addClass('is-active'); // Add class to all first panels
//$('ul.accordion:first-of-type li:first-of-type').addClass('is-active'); // Adds class to first panel in each section repeater
	$('.content-wrapper > div:first-of-type ul.accordion:first-of-type li:first-of-type').addClass('is-active'); // Adds class only to first panel (no childern)
	$('.is-active .accordion-content').css('display','block');
});



//*****************************************************************************
// Responsive Video: FITVIDS (Requires - jquery.fitvids.js)
//*****************************************************************************

(function($) {
  $(document).ready(function(){
    // Target your .container, .wrapper, .post, etc.
    $("#content").fitVids();
  });

  })(jQuery);



//*****************************************************************************
// Responsive Video: WORDPRESS (Requires CSS styles)
//*****************************************************************************
$(function() {
  $('.mejs-overlay-loading').closest('.mejs-overlay').addClass('load'); //just a helper class

  var $video = $('div.video video');
  var vidWidth = $video.attr('width');
  var vidHeight = $video.attr('height');

  $(window).resize(function() {
    var targetWidth = $(this).width(); //using window width here will proportion the video to be full screen; adjust as needed
    $('div.video, div.video .mejs-container').css('height', Math.ceil(vidHeight * (targetWidth / vidWidth)));
  }).resize();
});



// Using Jquery To Add A Dynamic “Back To Top” Floating Button With Smooth Scroll
jQuery(document).ready(function() {
    var offset = 220;
    var duration = 500;
    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > offset) {
            jQuery('.back-to-top').fadeIn(duration);
        } else {
            jQuery('.back-to-top').fadeOut(duration);
        }
    });

    jQuery('.back-to-top').click(function(event) {
        event.preventDefault();
        jQuery('html, body').animate({scrollTop: 0}, duration);
        return false;
    })
});



